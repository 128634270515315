import React from "react"
import dynamic from "next/dynamic"
import { useDispatch, useSelector } from "react-redux"

import { Formik } from "formik"
import * as Yup from "yup"
import { Form as FormikForm } from "formik"
import { Modal, Row } from "react-bootstrap"

import * as ga from "@/ga"

import NameField from "@/fields/nameField"
import EmailField from "@/fields/emailField"
import PhoneField from "@/fields/phoneField"
import MessageField from "@/fields/messageField"
import Recaptcha from "@/fields/recaptcha"
import SubmitButton from "@/fields/submitButton"

const SuccessToast = dynamic(() => import("@/toasts/successToast"))
const ErrorToast = dynamic(() => import("@/toasts/errorToast"))

import {
    toggleGetInTouchModal,
    resetGetInTouchModal,
    sendGetInTouchData,
} from "@/slices/getInTouchModalSlice"

function GetInTouchModal() {
    const dispatch = useDispatch()

    const { cookieConsent } = useSelector((state) => state.cookie)

    const generateLead = () => {
        if (cookieConsent === "true") {
            ga.event({
                action: "generate_lead",
            })
        }
    }

    // The selector to retrieve the get in touch form from state.
    // The selector to retrieve whether the get in touch modal should be
    // shown or hidden from state.
    const { status, showGetInTouchModal, recipient } = useSelector(
        (state) => state.getInTouchModal,
    )

    // A regular expression that can detect a small number of fake mobile
    // numbers that will be used for validation (doesn't allow chars).
    const phoneRegExp =
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    // The schema used for validation of the request get in touch form input.
    const GetInTouchSchema = Yup.object().shape({
        fullName: Yup.string().required("This field is required."),
        email: Yup.string()
            .email("Must be a valid email.")
            .required("This field is required."),
        phone: Yup.string().matches(
            phoneRegExp,
            "Must be a valid phone number (digits only).",
        ),
        captcha: Yup.boolean().oneOf(
            [true],
            "Please confirm you are not a robot.",
        ),
        message: Yup.string().required("This field is required."),
    })

    return (
        <>
            <SuccessToast
                status={status}
                resetModal={() => {
                    dispatch(resetGetInTouchModal())
                    generateLead()
                }}
            />
            <ErrorToast
                status={status}
                resetModal={() => dispatch(resetGetInTouchModal())}
            />
            {/* Get In Touch Form Modal */}
            <Modal
                show={showGetInTouchModal}
                onHide={() => dispatch(toggleGetInTouchModal(false))}
                fullscreen="lg"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Modal.Title>
                        <h2 className="text-center">Get in touch</h2>
                        <p className="text-center">
                            Simply enter your details and a member of our team
                            will be in touch shortly to answer your query.
                        </p>
                    </Modal.Title>

                    {/* Get In Touch Form */}
                    <Formik
                        initialValues={
                            recipient
                                ? {
                                      recipient: recipient,
                                      fullName: "",
                                      email: "",
                                      phone: "",
                                      captcha: false,
                                      message: "Hello " + recipient + ",\n",
                                  }
                                : {
                                      fullName: "",
                                      email: "",
                                      phone: "",
                                      captcha: false,
                                      message: "",
                                  }
                        }
                        validationSchema={GetInTouchSchema}
                        // Send the form data to the backend
                        onSubmit={async (values) => {
                            await dispatch(sendGetInTouchData(values))
                        }}
                    >
                        {(formik) => (
                            <FormikForm>
                                <Row>
                                    <NameField formik={formik} />

                                    <EmailField formik={formik} />

                                    <PhoneField formik={formik} />

                                    <MessageField formik={formik} />
                                </Row>

                                <div className="d-none d-sm-inline">
                                    <Recaptcha formik={formik} size="normal" />
                                </div>
                                <div className="d-sm-none">
                                    <Recaptcha formik={formik} size="compact" />
                                </div>

                                <SubmitButton
                                    submit_message="Send Message"
                                    status={status}
                                    formik={formik}
                                />
                            </FormikForm>
                            // Get In Touch Form End
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
            {/* Get In Touch Form Modal End */}
        </>
    )
}

export default GetInTouchModal
