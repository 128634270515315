import React from "react"

import { Field } from "formik"

import { Col, Form } from "react-bootstrap"

function MessageField(props) {
    return (
        <>
            {/* User Message Field */}

            <Col md={12}>
                <Form.Group className="form-input-group">
                    <Form.Label
                        htmlFor="message"
                        className="d-flex justify-content-between m-0 px-1"
                    >
                        <span
                            htmlFor="message"
                            className="text-semi-bold text-sm mb-2"
                        >
                            Message
                        </span>
                        {props.optional && (
                            <span className="text-normal text-sm mb-2">
                                Optional
                            </span>
                        )}
                    </Form.Label>
                    <Field
                        as="textarea"
                        id="message"
                        name="message"
                        className="form-control shadow-sm"
                        rows="6"
                        type="text"
                    ></Field>
                    {props.formik.errors.message &&
                    props.formik.touched.message ? (
                        <p className="text-danger text-sm">
                            {props.formik.errors.message}
                        </p>
                    ) : null}
                </Form.Group>
            </Col>

            {/* User Message Field End */}
        </>
    )
}

export default MessageField
